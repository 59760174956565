<template>
  <HtmlParser
    ref="htmlParserEl"
    v-bind="$attrs"
  />
</template>

<script lang="ts" setup>
import {
  getCurrentInstance,
  h,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onBeforeUpdate,
  onMounted,
  onServerPrefetch,
  ref,
  useAttrs,
} from 'vue'
import type { Config as DOMPurifyConfig } from 'dompurify'
import { withLeadingSlash, withoutLeadingSlash } from 'ufo'
import { sanitizeHTML as _sanitizeHTML } from './useHtmlParser'
import { navigateTo } from '#imports'

const props = withDefaults(
  defineProps<{
    html: string
    tag?: keyof HTMLElementTagNameMap
    options?: DOMPurifyConfig
    wysiwyg?: boolean
  }>(),
  {
    tag: 'div',
    options: () => ({}),
    wysiwyg: false,
  },
)

const content = ref<TrustedHTML>()
const lastSanitizedHTML = ref('') // to prevent unnecessary sanitization

/**
 * SSR purify support
 */
onServerPrefetch(async () => {
  content.value = await sanitizeHTML(props.html)
})

/**
 * Client-side purify support
 */
onBeforeUpdate(async () => {
  if (lastSanitizedHTML.value !== props.html) {
    content.value = await sanitizeHTML(props.html)
  }
  nextTick(addLinkSupport)
})
onMounted(async () => {
  const { isHydrating, payload } = useNuxtApp()
  if (!isHydrating && !payload.serverRendered) {
    content.value = await sanitizeHTML(props.html)
  }
  // add link support
  nextTick(addLinkSupport)
})
/**
 * Not always isHydrating property is enough.
 * Sometimes page is partly rendered on server and partly on client (like account page).
 * In that case we need to check if there is data attribute "data-ssr" on element.
 * If not, it means that element was created on client.
 */
onBeforeMount(async () => {
  const instance = getCurrentInstance()
  const createdInSSR
    = instance?.vnode?.el?.dataset?.ssr === 'true'
    || instance?.vnode?.el?.firstChild?.dataset?.ssr === 'true'
  if (!createdInSSR) {
    content.value = await sanitizeHTML(props.html)
  }
})

function SafeHtmlEl() {
  const attrs = useAttrs()
  return h(props.tag, {
    'data-el': 'html-parser',
    'innerHTML': content.value,
    'data-ssr': Boolean(import.meta.server),
    'data-test': attrs?.['data-test'] ?? '',
  })
}

async function sanitizeHTML(html: string) {
  if (html) {
    lastSanitizedHTML.value = html
  }

  return _sanitizeHTML(html)
}

function HtmlParser() {
  return props.wysiwyg
    ? h('div', { class: 'wysiwyg-content' }, [SafeHtmlEl()])
    : SafeHtmlEl()
}

defineExpose({ sanitizeHTML })

// Client-side navigation support - START
const htmlParserEl = ref<HTMLElement | null>(null)
const links = ref<HTMLAnchorElement[]>([])

function addLinkSupport() {
  if (!htmlParserEl.value) {
    return
  }
  links.value = Array.from(htmlParserEl.value.getElementsByTagName('a'))
  if (links.value?.length) {
    for (let i = 0; i < links.value.length; i++) {
      links.value[i].addEventListener('click', navigate, false)
    }
  }
}

function removeLinkSupport() {
  if (links.value.length) {
    for (let i = 0; i < links.value.length; i++) {
      links.value[i].removeEventListener('click', navigate, false)
    }
    links.value = []
  }
}
function navigate(e: MouseEvent) {
  const target = e.currentTarget
  if (target instanceof HTMLAnchorElement) {
    return redirect(e, target)
  }
}

function redirect(e: MouseEvent, target: HTMLAnchorElement) {
  const href = target.getAttribute('href')
  const hrefTarget = target.getAttribute('target')
  const isCtrlKeyPressed = e.ctrlKey || e.metaKey
  const openInNewTab
    = (hrefTarget && hrefTarget === '_blank') || isCtrlKeyPressed
  // If link is local, not set to open in a new tab,
  // and Ctrl (or Cmd) key is not pressed, navigate with router link
  if (href && href[0] === '/' && !openInNewTab) {
    e.preventDefault()
    // remove BASE_URL from href
    const slugs = withoutLeadingSlash(href).split('/')
    slugs?.shift()
    const url = slugs?.join('/')
    navigateTo(withLeadingSlash(url))
  }
}

onBeforeUnmount(() => removeLinkSupport())
// Client-side navigation support - END
</script>
